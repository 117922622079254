import React, { Component } from 'react';
import styled from 'styled-components';
import { colors, breakpoints, fonts } from '../../style-utilities/variables';

const StyledBenefitsContainer = styled.section`
  padding: 1em 0;
  background-color: ${colors.secondaryGray};
`;
const StyledBenefits = styled.div`
  display: block;
  width: 100%;
  padding-left: 40px;
  padding-right: 20px;
  ul {
    line-height: 15px;
    margin-bottom: 0;
    list-style-image: url('https://api.dev.ansira.io/wp-content/uploads/2019/03/bullet-check.png');
  }
  li span {
    position: relative;
    top: -7px;
    color: ${colors.black};
  }
  @media (min-width: ${breakpoints.tablet}) {
    padding-right: 10px;
  }
`;
const StyledBenefitsRow = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  justify-content: center;
  max-width: 60em;
  margin: 0 auto;
  background-color: ${colors.secondaryGray};
  @media (max-width: ${breakpoints.mobileMax}) {
    display: block;
  }
`;

const StyledTitle = styled.h2`
  font-weight: bold;
  color: ${colors.black};
  text-align: center;
  margin: 0;
  margin-bottom: 1em;
`;

class Benefits extends Component {
  render() {
    const data = this.props.benefits,
      chunk_size = 6;
    const groups = data
      .map(function(e, i) {
        return i % chunk_size === 0 ? data.slice(i, i + chunk_size) : null;
      })
      .filter(function(e) {
        return e;
      });
    return (
      <StyledBenefitsContainer className="clearfix">
        <StyledTitle>Perks of the Job</StyledTitle>
        <StyledBenefitsRow>
          {groups.map((set, idx) => {
            return (
              <StyledBenefits key={idx}>
                <ul>
                  {set.map((item, i) => {
                    return (
                      <li key={i}>
                        <span>{item.benefit_title}</span>
                      </li>
                    );
                  })}
                </ul>
              </StyledBenefits>
            );
          })}
        </StyledBenefitsRow>
      </StyledBenefitsContainer>
    );
  }
}
export default Benefits;
