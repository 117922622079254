import React, { Component } from "react";
import styled from "styled-components";
import { colors, breakpoints, fonts } from "../../style-utilities/variables";

const StyledValuesContainer = styled.div`
  .circle {
    position: relative;
    display: block;
    margin: 2em auto;
    border: 24px ${colors.primary} solid;
    border-radius: 50%;
    color: ${colors.white};
    max-width: 1000px;
    &:before {
      content: "";
      border: 40px ${colors.white} solid;
      background-color: ${colors.black};
      display: block;
      position: absolute;
      border-radius: 50%;
      top: -1px;
      left: -1px;
      bottom: -1px;
      right: -1px;
    }
    &:after {
      display: block;
      padding-bottom: 100%;
      width: 100%;
      height: 0;
      content: "";
    }
    .circle-inner {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .circle-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .circle-content {
          width: 90vw;
        }
      }
    }
    ul {
      font-weight: bold;
      line-height: 1.4em;
      li {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }

  .circle-container {
    position: relative;
    left: -50%;
    width: 200vw;
    max-width: 1500px;
    margin: 0 auto;
  }
  @media screen and (min-width: 500px) {
    .circle {
      .circle-inner {
        .circle-wrapper {
          .circle-content {
            width: 90vw;
          }
        }
      }
      ul {
        line-height: 1.6em;
        li {
          font-size: 20px;
          margin-bottom: 40px;
        }
      }
    }
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    .circle-container {
      position: relative;
      left: 0%;
      width: 100%;
      padding: 2em;
      .circle {
        .circle-inner {
          .circle-wrapper {
            .circle-content {
              width: 60%;
            }
          }
        }
        ul {
          line-height: 1.6em;
          li {
            font-size: 22px;
            margin-bottom: 32px;
          }
        }
      }
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    .circle {
      ul {
        line-height: 2em;
        li {
          font-size: 26px;
          margin-bottom: 45px;
        }
      }
    }
  }
  @media screen and (min-width: ${breakpoints.latopLG}) {
    .circle-container {
      left: 11%;
      width: 78%;
      padding: 4em;
      .circle {
        .circle-inner {
          .circle-wrapper {
            .circle-content {
              width: 50%;
            }
          }
        }
      }
    }
  }
`;
const StyledTitle = styled.h2`
  margin: 0 auto 0.8em;
  color: ${colors.white};

  @media (min-width: ${breakpoints.tablet}) {
    margin: 1em auto 0.8em;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin: 0 auto 1.5em;
  }
`;

class Values extends Component {
  render() {
    return (
      <StyledValuesContainer>
        <div className="circle-container">
          <div className="circle">
            <div className="circle-inner">
              <div className="circle-wrapper">
                <div className="circle-content">
                  <StyledTitle>{this.props.headline}</StyledTitle>
                  <ul>
                    {this.props.values.map((item, index) => {
                      return <li key={index}>{item.value_title}</li>;
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledValuesContainer>
    );
  }
}
export default Values;
