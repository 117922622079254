import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import ButtonGrey from "../ButtonGrey";
import { colors, breakpoints, fonts } from "../../style-utilities/variables";
import ReactHtmlParser from "react-html-parser";

const Div = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  .info-container {
    background-color: ${colors.white};
    padding: 2em;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    h3 {
      font-size: 22px;
    }
  }
`;
const StyledLocationContainer = styled.div`
  width: 96%;
  margin: 2%;
  a {
    text-decoration: none;
  }
  @media (min-width: ${breakpoints.tablet}) {
    width: 46%;
    margin: 2%;
  }
  @media (min-width: ${breakpoints.desktop}) {
    width: 31.3333%;
    margin: 1%;
  }
  @media (min-width: 1400px) {
    width: 23%;
    margin: 1%;
    &:nth-child(4n) {
      margin: 1% 0 1% 1%;
    }
    &:nth-child(1+ 5n) {
      margin: 1% 1% 1% 0;
    }
  }
`;

const Location = () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressWpLocations(sort: { fields: slug, order: ASC }) {
          edges {
            node {
              slug
              title
              content
              acf {
                address
                phone
                hours
              }
              better_featured_image {
                source_url
              }
            }
          }
        }
      }
    `}
    render={data => {
      const locations = data.allWordpressWpLocations.edges;
      // fcn to filter for city:  st-louis dallas portland
      const filterForCity = (arr, city) => {
        return arr.filter(item => item.node.slug === city);
      };
      const stLouis = filterForCity(locations, "st-louis");
      const dallas = filterForCity(locations, "dallas");
      const portland = filterForCity(locations, "portland");
      // fcn to filter out the cities above and then sort alphabetically
      const filteredRemaining = locations.filter(
        function(e) {
          return this.indexOf(e.node.slug) < 0;
        },
        ["st-louis", "dallas", "portland"]
      );
      const newLocations = [
        ...stLouis,
        ...dallas,
        ...portland,
        ...filteredRemaining
      ];
      return (
        <Div>
          {newLocations.map(item => {
            return (
              <StyledLocationContainer>
                <div className="info-container">
                  <h3>{item.node.title}</h3>
                  {ReactHtmlParser(item.node.acf.address)}
                  <ButtonGrey
                    textLabel="View Location"
                    pageLink={"contact/" + item.node.slug}
                  >
                    VIEW LOCATION
                  </ButtonGrey>
                </div>
              </StyledLocationContainer>
            );
          })}
        </Div>
      );
    }}
  />
);

export default Location;
