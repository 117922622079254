import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import ReactHTMLParser from "react-html-parser";
import { colors, breakpoints, fonts } from "../../style-utilities/variables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faTwitterSquare,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import ButtonGrey from "../ButtonGrey";

const AboutExtras = styled.div`
  margin-top: 4em;
  display: flex;
  flex-direction: column;
  .red-circle,
  .gray-circle,
  .black-circle {
    position: relative;
    align-self: center;
  }
  .red-circle {
    border: 12px solid ${colors.primary};
    background: white;
  }
  .gray-circle {
    background-color: ${colors.secondaryGray};
    color: ${colors.black};
  }
  .black-circle {
    background-color: ${colors.black};
    color: ${colors.white};
    h2 {
      color: ${colors.white};
    }
  }

  .margin-bottom {
    margin-bottom: 2em;
  }
  .last {
    margin-top: -100px;
  }

  .social-icons {
    margin: auto;
    display: flex;
    justify-content: space-between;
    max-width: 260px;
    a {
      font-size: 32px;
      color: ${colors.black};
      &:hover {
        color: ${colors.secondaryRed};
        text-decoration: underline;
      }
    }
  }

  @media (min-width: 500px) {
    .red-circle {
      align-self: flex-end;
      width: 600px;
      height: 600px;
      border: 8px solid ${colors.primary};
      right: -50px;
    }
    .gray-circle {
      align-self: flex-start;
      left: -40px;
    }
    .black-circle {
      align-self: center;
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    .gray-circle {
      margin-top: -18%;
    }
    .red-circle {
      width: 800px;
      height: 800px;
    }
  }
  @media (min-width: 1500px) {
    .red-circle {
      right: 0;
    }
  }
  @media (min-width: ${breakpoints.laptopLG}) {
    .red-circle {
      width: 900px;
      height: 900px;
    }
  }
`;
const StyledBlock = styled.div`
  border-radius: 50%;
  width: 130vw;
  height: 130vw;
  background-color: #000000;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0em;

  p {
    width: 75%;
    font-size: 26px;
  }
  @media (min-width: 500px) {
    width: 500px;
    height: 500px;
  }
`;

const Testament = ({ testamentData, ...remProps }) => {
  const blocks = [...testamentData].slice(0, 3);
  return (
    <AboutExtras {...remProps}>
      <StyledBlock className="red-circle margin-bottom">
        <h2>{blocks[0].title}</h2>
        <p>{blocks[0].content}</p>
        <ButtonGrey textLabel={blocks[0].cta} pageLink={blocks[0].link} />
      </StyledBlock>
      <StyledBlock className="gray-circle">
        <h2>{blocks[1].title}</h2>
        <p>
          <div className="social-icons">
            <a
              href="https://www.facebook.com/Ansira/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
            </a>
            <a
              href="https://twitter.com/ansira"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitterSquare} size="2x" />
            </a>
            <a
              href="https://www.linkedin.com/company/ansira/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
          </div>
        </p>
      </StyledBlock>
      <StyledBlock className="black-circle last">
        <h2>{blocks[2].title}</h2>
        <p>{blocks[2].content}</p>
        <ButtonGrey
          textLabel={blocks[2].cta}
          pageLink={blocks[2].link}
          className="whiteCTA"
        />
      </StyledBlock>
    </AboutExtras>
  );
};

export default Testament;
