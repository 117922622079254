import React, { Component } from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import { colors, breakpoints, fonts } from "../style-utilities/variables";
import ReactHtmlParser from "react-html-parser";

//SECTIONS
import ButtonRed from "../components/ButtonRed";
import ImageCircle from "../components/ImageCircle";
import Values from "../components/Careers/Values";
import Benefits from "../components/Careers/Benefits";
import Testament from "../components/Careers/Testament";
import NavBar from "../components/NavBar";
import Hero from "../components/Hero";
import ContactBanner from "../components/ContactBanner";
import SEO from "../components/SEO";
import DefaultSharingImage from "./../static/ansira-logo-sharing.png";
import Location from "../components/Careers/OurLocations";

//careers data for the testament component:
const testamentDataCareers = [
  {
    type: "insights",
    title: "We're Always Thinking",
    content: "Read the latest insights from our marketing whizzes.",
    link: "/insights",
    cta: "See Insights"
  },
  {
    type: "social",
    title: "We Are Social",
    content: "",
    link: [
      "https://twitter.com/ansira",
      "https://www.facebook.com/Ansira/",
      "https://www.linkedin.com/company/ansira/"
    ],
    cta: ""
  },
  {
    type: "locations",
    title: "Where to Find Us",
    content:
      "We have offices from St. Louis to London.",
    link: "/contact",
    cta: "See Locations"
  }
];

const StyledCareers = styled.main`
  overflow-x: hidden;
  @media (min-width: 1500px) {
    overflow-x: visible;
  }
  .max-width {
    max-width: 1500px;
    margin: 0 auto;
  }
  .intro-copy {
    width: 100%;
  }
  .row {
    margin-right: 0;
    margin-left: 0;
  }
  .col-md-7 {
    padding-right: 0;
    padding-left: 0;
  }
  .locations-container {
    background-color: ${colors.secondaryGray};
    padding: 2em 0;
    h2 {
      font-size: 26px;
      text-align: center;
    }
  }
  .bullet-section {
    padding: 4em 0;
  }
  @media (min-width: ${breakpoints.desktop}) {
    .bullet-section {
      padding: 8em 0;
    }
  }

  .sub-section {
    p {
      font-weight: 400;
      color: ${colors.primaryGray};
    }

    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 2rem;

      h2 {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 0.8rem;
        @media (min-width: ${breakpoints.tablet}) {
          font-size: 28px;
        }
      }
    }

    .img-section {
      padding: 0px 0 40px;
    }
    .left {
      order: 1;
    }
    .right {
      order: 2;
    }
    .body-copy {
      font-size: 22px;
      width: 100%;
    }

    @media (max-width: ${breakpoints.mobileMax}) {
      .img-section {
        width: 120vw;
        margin: 0 calc(-150px + 20vw);
      }
    }

    @media (min-width: ${breakpoints.tablet}) {
      position: relative;
      .img-section {
        padding: 0;
        &.left {
          right: 190px;
        }
        &.right {
          left: 80px;
        }
      }
    }

    @media (min-width: ${breakpoints.max}) {
      .img-section {
        &.left {
          right: 140px;
        }
        &.right {
          left: 110px;
        }
      }
    }
  }
`;

class Careers extends Component {
  state = {
    isPreviewMode: false,
    previewPayload: null
  };

  componentDidMount() {
    this.setState({
      isBrowser: typeof window !== "undefined",
      viewPort: window.innerWidth >= 768 ? "desktop" : "mobile"
    });
    if (this.state.isBrowser) {
      window.addEventListener("resize", this.setViewport);
    }
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get("preview")) {
      let id = params.get("id");
      let wpnonce = params.get("_wpnonce");
      let BASE_URL = `https://api.dev.ansira.io/wp-json/wp/v2`;

      fetch(`${BASE_URL}/pages/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: "cors",
        credentials: "include"
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */
  }

  render() {
    let careers_page = this.props.data.wordpressPage;

    if (this.state.isPreviewMode) {
      careers_page.title = this.state.previewPayload.title.rendered;
      careers_page.content = this.state.previewPayload.content.rendered;
      careers_page.acf = this.state.previewPayload.acf;
    }

    return (
      <StyledCareers className="animate">
        {!this.props.isPreviewMode && (
          <SEO
            isBlogPost={false}
            postData={this.props.data.wordpressPage}
            postImage={DefaultSharingImage}
          />
        )}
        <Hero
          headline={careers_page.acf.hero_headline}
          sub={careers_page.acf.hero_sub_headline}
        />

        <div className="page-container max-width">
          <div className="row sub-section">
            <div className="col-md-7 content copy left">
              <div className="intro-copy">
                {ReactHtmlParser(careers_page.acf.intro_copy)}
              </div>

              <h2>{careers_page.acf.content_blocks[0].heading}</h2>
              <div className="body-copy">
                {ReactHtmlParser(careers_page.acf.content_blocks[0].content)}
              </div>
            </div>
            <div className="col-md-5 img-section right">
              <ImageCircle
                img={careers_page.acf.content_blocks[0].image.source_url}
              />
            </div>
          </div>
          <div className="row sub-section">
            <div className="col-md-7 content copy right">
              <h2>{careers_page.acf.content_blocks[1].heading}</h2>
              <div className="body-copy">
                {ReactHtmlParser(careers_page.acf.content_blocks[1].content)}
              </div>

              <ButtonRed
                textLabel="Search Jobs"
                pageLink={"https://jobs.lever.co/ansira"}
                pageTarget="_blank"
              />
            </div>
            <div className="col-md-5 img-section left">
              <ImageCircle
                img={careers_page.acf.content_blocks[1].image.source_url}
              />
            </div>
          </div>
        </div>

        <Values
          values={careers_page.acf.values}
          headline={careers_page.acf.values_headline}
        />
        <div className="page-container max-width">
          <div className="bullet-section">
            <h2>{careers_page.acf.bullet_list_headline}</h2>
            <div className="bullet-container">
              <ul>
                {careers_page.acf.bullet_list_1.map((item, i) => {
                  return <li className="copy">{item.list_item}</li>;
                })}
              </ul>
              <ul>
                {careers_page.acf.bullet_list_2.map((item, i) => {
                  return <li className="copy">{item.list_item}</li>;
                })}
              </ul>
              <ul>
                {careers_page.acf.bullet_list_3.map((item, i) => {
                  return <li className="copy">{item.list_item}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>

        <div className="locations-container">
          <div className="max-width page-container">
            <h2>Our Locations</h2>
            <Location />
          </div>
        </div>

        <div className="max-width">
          <Testament testamentData={testamentDataCareers} />
          <ContactBanner
            headline={careers_page.acf.contact_headline}
            cta={careers_page.acf.contact_cta}
            url={careers_page.acf.contact_url}
          />
        </div>
      </StyledCareers>
    );
  }
}

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "careers" }) {
      content
      slug
      title
      type
      acf {
        seo_title
        seo_canonical
        seo_description
        intro_copy
        benefits_perks {
          benefit_title
        }
        values_headline
        hero_headline
        hero_sub_headline
        values {
          value_title
        }
        content_blocks {
          content
          heading
          image {
            source_url
          }
        }
        contact_headline
        contact_cta
        contact_url
        bullet_list_headline
        bullet_list_1 {
          list_item
        }
        bullet_list_2 {
          list_item
        }
        bullet_list_3 {
          list_item
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
  }
`;

export default Careers;
